<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form#FormTagsAddRemove(:endpoint='`/_/Projects/${projectId}/ProjectTags/EditForm`' updateOnSubmitSuccess='AddTag,EditTag' @submitSuccess='$emit("close")')
    template(#default='{ config }')
      form-header(@cancel='$emit("close")')
        span(class='font-light' v-html='$t("Change tags for <b>{0} selected</b> products", "", [productIds.length])')
      template(v-if='config.items && config.items.Tags.options && config.items.Tags.options.length > 0')
        div(class='bleed')
          form-select-list(class='' name='Tags' mutliple @update:modelValue='onUpdate')
            template(#actions='{ item }')
              btn(faded icon='custom-edit' :data-tooltip='$t("Edit Tag")' @click='$utils.routeQueryAdd({ "form-project-tag-edit": { projectId, tagName: item.value, size: "sm" } })')
        form-hidden(name='Products' :modelValue='productIds')
        form-footer
          template(#secondary)
            form-action(:primary='true' name='Add' :disabled='noItemsSelected')
            form-action(name='Remove' :disabled='noItemsSelected')

      empty-state(v-else :centered='true')
        template(#message)
          span {{ $t('There are no tags in this project yet.') }}
        template(#buttons)
          btn(secondary icon='plus' @click='$utils.routeQueryAdd({ "form-project-tag-create": projectId })') {{ $t('Add Tag') }}
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref } from 'vue'

const FormProjectTagsAddRemove = defineComponent({
  inheritAttrs: false,
  props: {
    productIds: { type: Array as PropType<string[]>, required: true },
    projectId: { type: String, required: true },
  },
  setup() {
    const value = ref<string[]>([])
    return {
      value,
      onUpdate: (v: string[]) => (value.value = v),
      noItemsSelected: computed(() => value.value.length === 0),
    }
  },
})
export default FormProjectTagsAddRemove
</script>
